
import React, { useState, useEffect, useRef } from "react";
import {
    auth, signInAnonymously, child, Init,
    get, ref, getDatabase,
} from './components/firebase'
import header from './images/Header.png'
import TextMatter from './images/Text_Matter.png'
import Button from './images/Button.png'
import Bottom from './images/Bottom.png'
import RealityReach from './images/RealityReach.png'
import styles from './styles/Home.module.css'

import { useNavigate } from 'react-router-dom'
import { FetchServerSubscriptionState } from "./functions/db-functions/profileFunctions";
import UserName from "./UserName";

export default function Home() {

    const db = getDatabase()
    const navigate = useNavigate()
    useEffect(() => {
        const init = Init()
    }, []);
    const subscriptionState = useRef(null)


    async function VerifyProfileKey() {
        var dbRef = ref(db)

        get(child(dbRef, "vision/card/userHashes/" + StringSplitter())).then(async (snapshot) => {
            if (snapshot.exists()) {

                if (await CheckSubscriptionState(snapshot.val(), StringSplitter())) {
                    if (snapshot.val().SubscriptionDetails.SubscriptionState == "active") {
                       // LoginAnonymously(snapshot.val().UserName)
                       Redirect("UserName", StringSplitter())
                    }
                    else {
                        ///Develop UI Rework
                        //setPrompt({ type: "info", message: "Profile has been deactivated. Please contact owner for details." })
                        alert("Profile has been deactivated. Please contact owner for details.")
                    }
                }
                else {
                    alert("Profile has been deactivated.")
                }
            }
            else {
                alert("You are not authorized to access this experience")
            }
        })
    }


    async function CheckSubscriptionState(snapshot, profileHash) {
        var currentDate = new Date()
        var day = currentDate.getDate()
        var month = currentDate.getMonth() + 1
        var year = currentDate.getFullYear()

        const date1 = new Date(snapshot.SubscriptionDetails.LastChecked)
        const date2 = new Date(month + "/" + day + "/" + year)
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 365) {
            await FetchServerSubscriptionState({
                subId: snapshot.SubscriptionDetails.SubscriptionID,
                profileHash, subscriptionState
            })
            if (subscriptionState.current == "active") {
                return true
            }
            else {
                return false
            }
        }
        else {
            return true
        }
    }

    function LoginAnonymously(UserName) {
        signInAnonymously(auth)
            .then(() => {
                Redirect(UserName, StringSplitter())
                /*  alert(auth.currentUser.uid)  */
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
            });
    }

    function Redirect(value, key) {
        navigate(value + "/?s=" + key)
    }



    function StringSplitter() {
        //http://localhost:3001/?s=dada
        var keyArray = window.location.search.split('=')
        //var keyArray2 = window.location.search
        var key = keyArray[1]
        return key
    }

    function Click() {

        VerifyProfileKey()
    }



    return (<>

        <head>
            <title>Vision Card</title>
            <meta name="description" content="Landing page for the Vision Card." />
            <link rel="icon" href="https://reality-reach-assets.storage.googleapis.com/ar-card/images/RealityReach_black.png" />
            {/*  <link rel="icon" href="/favicon.ico" /> */}
        </head>


        <UserName />
        <div className={styles.container}>

            <footer className={styles.footer}>
                <a
                    href="https://realityreach.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className={styles.logo}>
                        <img sx={{
                            margin: '10px'
                        }}
                            src={RealityReach}
                            alt="Reality Reach Logo" width={150} height={63.15}
                        />
                    </span>
                </a>
            </footer>


        </div>


    </>)
}