


export default function PdfTileIconAR({
    pdfTileState,
    pageOneFiles,
    setPage }) {

    var IntegrationHubPng = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/integration_hub/IntegrationHub.png'
    var VRPng = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/vr/VR.png'
    var VerticalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/tiles/vertical.gif'
    var HorizontalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/tiles/horizontal.gif'

    var PdfImage = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/pdf/pdfTile.png'
    var HorizontalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/accents/Horizontal.gif'


    console.log(pdfTileState.activeTile)
    function PdfTilePng() {
        var PDFImage = 'https://cdn.discordapp.com/attachments/1069195643211481138/1074528644719972473/TILE_PDFoffcentre.png'
        console.log(pageOneFiles.PDF)
        return pageOneFiles.PDF ? (<>
            <a-plane
                src={PdfImage}
                width="0.5"
                class="clickable button pageOne ui"
                shader="flat"
                height="0.5"
                opacity="0.99"
                onClick={() => setPage("PDF")}
                scale="1 1 1"
                position="+0.265 -0.6 0" />
        </>) :
            (
                <a-plane
                    src={pageOneFiles.DynamicTile}
                    width="0.5"
                    class="clickable button pageOne ui"
                    shader="flat"
                    height="0.5"
                    opacity="0.99"
                    scale="1 1 1"
                    position="0.265 -0.6 0"
                />)

    }


    function Logo() {
        return pageOneFiles.Logo ?
            <a-plane
                src={pageOneFiles.Logo}
                width="0.5"
                class="clickable button pageOne ui"
                shader="flat"
                height="0.5"
                opacity="0.99"
                scale="1 1 1"
                position="0.265 -0.6 0"
            /> : (
                <a-plane
                    src={pageOneFiles.DynamicTile}
                    width="0.5"
                    class="clickable button pageOne ui"
                    shader="flat"
                    height="0.5"
                    opacity="0.99"
                    scale="1 1 1"
                    position="0.265 -0.6 0"
                />)
    }

    return (pdfTileState.activeTile == "PDF") ? (
        <PdfTilePng />
    ) :
        (<Logo />)
}




export function PdfTileIconStatic({
    pdfTileState,
    pageOneFiles,
    setPage }) {

    var IntegrationHubPng = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/integration_hub/IntegrationHub.png'
    var VRPng = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/vr/VR.png'
    var VerticalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/tiles/vertical.gif'
    var HorizontalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/tiles/horizontal.gif'

    console.log(pdfTileState.activeTile)
    function PdfTilePng() {
        var PDFImage = 'https://cdn.discordapp.com/attachments/1069195643211481138/1074528644719972473/TILE_PDFoffcentre.png'
        console.log(pageOneFiles.PDF)
        return pageOneFiles.PDF ? (<>
            <img
            onClick={()=>{setPage("PDF")}}
                src={PDFImage}
                width="100%"
                height="100%"
                />
        </>) :
            (
                <img
                    src={pageOneFiles.DynamicTile}
                    height='100%'
                    width='100%'
                />)

    }


    function Logo() {
        return pageOneFiles.Logo ?
            <img
                src={pageOneFiles.Logo}
                width='100%'
                height='100%'
            /> : (
                <img
                    src={pageOneFiles.DynamicTile}
                    width='100%'
                height='100%'
                />)
    }

    return (pdfTileState.activeTile == "PDF") ? (
        <PdfTilePng />
    ) :
        (<Logo />)
}

