
import { jump, DoNothing } from "./GameFunctions"
import './eventHandler.css'

export default function EventHandler({ GameState, setGameState, jumping }) {
    return (
        <>
            <div 
            id='event-overlay'
            /* style={{
                height: '100vh', width: '100vw',
                background:'black',
                position: 'fixed', zIndex:999999
            }} */
                class="clickable"
                onClick={() => {jump(GameState, setGameState, jumping)}}
            >
            </div>
        </>)
}